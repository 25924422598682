import React from 'react';
import { notification } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import Product, { useProduct, useProductData } from 'components/Products';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import { countApprovedPostsStats, countRequiredPost } from 'helpers/counters';
import {
  brandArchiveProduct,
  brandReactivateProduct,
  brandUnlistProduct,
  brandRelistProduct,
} from 'lib/Firebase/callables/brands';
import {
  CAMPAIGN_HISTORY_PATH,
  toCampaignApprovalCompletedMediaPath,
  toCampaignApprovalCompletedPath,
} from 'constants/routes';
import { CAMPAIGN_TYPE } from 'constants/campaigns';
import CreatorContent from 'components/CreatorContent';
import { ReactComponent as ArrowIcon } from 'images/components/Products/RecentPosts/Arrow.svg';
import { useSession } from 'components/Session';
import { PLACEHOLDER_IMG_URL } from 'constants/common';

/**
 *
 * @param {string[]} uids
 * @param {Record<string, import('types').CreatorContent>} creatorContents
 */
const getRecentContents = (uids, creatorContents) => {
  return uids
    .sort((a, b) => creatorContents[b].approvedAt - creatorContents[a].approvedAt)
    .slice(0, 4);
};

/**
 * Summary
 *
 * @type {React.FC<{}>}
 */
const Summary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const creatorContents = useSelector(selectors.getCreatorContents());
  const { campaignId } = useParams();
  const {
    approvedPartnershipUIDs,
    creatorContentUIDs: { approved: approvedCreatorContentUIDs },
    postUIDs: { approved: approvedPostUIDs },
  } = useProductData();
  const product = useProduct(campaignId);
  const {
    brand: { standardCredits },
  } = useSession();

  const partnerships = useSelector(selectors.getPartnerships());
  const posts = useSelector(selectors.getPosts());

  const [likeCount, commentCount] = countApprovedPostsStats(approvedPostUIDs, posts);
  const requiredPosts = countRequiredPost(approvedPartnershipUIDs, partnerships);

  async function handleArchive() {
    try {
      const updatedProduct = await brandArchiveProduct(campaignId);
      dispatch(actions.session.addCompletedProductsSession({ [campaignId]: updatedProduct }));

      notification.success({
        message: 'Campaign',
        description: 'Added Campaign to archive.',
        duration: 2,
      });
      history.push(CAMPAIGN_HISTORY_PATH);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: String(e.message) || 'Something went wrong',
        duration: 3,
      });
    }
  }

  async function handleReactivate() {
    try {
      const updatedProduct = await brandReactivateProduct(campaignId);
      dispatch(actions.session.addActiveProductsSession({ [campaignId]: updatedProduct }));

      notification.success({
        message: 'Campaign',
        description: 'Campaign added back to store.',
        duration: 2,
      });
      history.push(`/campaigns/${campaignId}/summary`);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: String(e.message) || 'Something went wrong',
        duration: 3,
      });
    }
  }

  async function handleUnlist() {
    try {
      await brandUnlistProduct(campaignId);
      notification.success({
        message: 'Campaign',
        description: 'Campaign removed from store.',
        duration: 2,
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: String(e.message) || 'Something went wrong',
        duration: 2,
      });
    }
  }

  async function handleRelist() {
    try {
      await brandRelistProduct(campaignId);
      notification.success({
        message: 'Campaign',
        description: 'Campaign added back to store.',
        duration: 2,
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: String(e.message) || 'Something went wrong',
        duration: 3,
      });
    }
  }

  const { type = CAMPAIGN_TYPE.SOCIAL } = product;
  const recentContents = getRecentContents(approvedCreatorContentUIDs, creatorContents);

  function summaryContent() {
    product.image = product.image || PLACEHOLDER_IMG_URL;

    const isPlaceholder = product.image.indexOf('/assets/images/') !== -1;

    // Only show if isListed or hasCredits & is not a trial image
    if (!isPlaceholder || product.isListed || standardCredits > 0) {
      return (
        <div id="image-container">
          <img src={product.image} alt="brand" style={{ objectFit: 'cover' }} />
        </div>
      );
    }

    return (
      <>
        <iframe
          style={{ maxWidth: '100%' }}
          width="474px"
          height="272px"
          src="https://www.youtube.com/embed/mzuBePONGK4?controls=1&mute=1&showinfo=0&rel=0&autoplay=1&autohide=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <p className="video-text">
          Your project will be approved by our team momentarily and you will receive applications
          from content creators today!
        </p>
      </>
    );
  }

  return (
    <div className="campaign-summary">
      <article className="campaign-summary-wrapper">
        <section className="left-column">
          {summaryContent()}
          {type === CAMPAIGN_TYPE.SOCIAL ? (
            <Product.Stats
              creators={approvedPartnershipUIDs.length}
              activePosts={approvedPostUIDs.length}
              requiredPosts={requiredPosts}
              likes={likeCount}
              comments={commentCount}
            />
          ) : (
            <Product.StyleCategory product={product} />
          )}

          <Product.PostGuidelines product={product} />
          {product.styleGuide && Object.keys(product.styleGuide).length > 0 && (
            <Product.Styleguides product={product} />
          )}
        </section>
        <section className="right-column">
          <div id="campaignRecentPosts">
            <div className="latest-content">
              <p className="side-header-text">Latest Content</p>
              <Link
                to={
                  type === CAMPAIGN_TYPE.SOCIAL
                    ? toCampaignApprovalCompletedPath(campaignId)
                    : toCampaignApprovalCompletedMediaPath(campaignId)
                }
              >
                See All
                <ArrowIcon className="see-all-icon" />
              </Link>
            </div>
            <div className="recent-content">
              {recentContents.map(uid => (
                <CreatorContent.RecentCard key={uid} creatorContentUID={uid} />
              ))}
            </div>
          </div>
          <Product.Details product={product} />
          {type === CAMPAIGN_TYPE.SOCIAL && <Product.PostRequirements product={product} />}
        </section>
      </article>
      <Product.Settings
        onArchive={handleArchive}
        onReactivate={handleReactivate}
        onUnlist={handleUnlist}
        onRelist={handleRelist}
      />
    </div>
  );
};

export default Summary;
